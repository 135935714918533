<template>
  <div class="card server-card">
    <div class="card-body">
      <h3 class="card-title">{{ title }}</h3>
      <el-select
        v-model="selectedServer"
        placeholder="Select..."
        @change="configure"
      >
        <el-option
          v-for="server in servers"
          :key="server.id"
          :label="server.name.toUpperCase()"
          :value="server.id"
        >
        </el-option>
      </el-select>
      <div class="btn-center">
        <button
          class="btn btn-outlined"
          :disabled="!selectedServer"
          @click="showModal = true"
        >
          Configure
        </button>
        <slot />
      </div>
    </div>
  </div>
  <el-dialog
    v-model="showModal"
    title="Configure the server"
    custom-class="config-modal"
    :destroy-on-close="true"
  >
    <template v-for="spec in serverConfig" :key="spec.key">
      <div class="card modal-card">
        <ServerConfig
          :spec="spec"
          :maxSlots="maxSlots"
          :soc="soc"
          :config="config"
          :filters="filters"
          @setConfig="setConfig"
          @setFilter="setFilter"
        />
      </div>
    </template>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="showModal = false">Cancel</el-button>
        <el-button type="primary" @click="saveConfig">Configure</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import serverConfig from '@/utils/data/serverConfig';
import ServerConfig from '@/components/configure/ServerConfig';

export default {
  name: 'CompareItem',
  props: ['title'],
  emits: ['selected', 'updateData', 'reset'],
  components: {
    ServerConfig,
  },
  data() {
    return {
      showModal: false,
      selectedServer: null,
      servers: [],
      serverConfig,
      maxSlots: {},
      config: {},
      soc: false,
      filters: {},
    };
  },
  async created() {
    const servers = await this.$store.dispatch('getServerList');
    let serverList = [];
    for (let i in servers) {
      serverList.push({
        name: `${servers[i].brand} ${servers[i].name}`,
        id: servers[i].product_id,
      });
    }
    this.servers = serverList;
  },
  methods: {
    async configure() {
      this.$emit('reset');
      const server = await this.$store.dispatch(
        'getServerById',
        this.selectedServer
      );

      console.log(server);
      this.getDefaultConfig(server);
      this.serverModal = true;
    },
    setConfig(key, config) {
      this.config[key] = config;
    },
    setFilter(key, value) {
      this.filters[key] = value;
    },
    async saveConfig() {
      const serverData = {
        server: {
          id: this.selectedServer,
        },
        ...this.config,
      };

      console.log(serverData);

      const results = await this.$store.dispatch('getEolData', serverData);

      console.log(results);
      const server = {
        server: {
          id: this.selectedServer,
          quantity: this.quantity,
        },
        results: results,
        config: this.config,
      };

      this.$emit('updateData', server);
      this.showModal = false;
    },
    getDefaultConfig(server) {
      this.maxSlots = {};
      this.maxSlots = {
        cpu: server.number_of_cpu_slot,
        ram: server.number_of_ram_slot,
        expansion: server.number_of_expansion_slot,
        fan: server.number_of_fan_slot,
        psu: server.number_of_psu_slot,
        storage: {
          lff: server.storage_form_factor.lff,
          sff: server.storage_form_factor.sff,
        }
      };

      this.filters = {};
      this.filters = {
        cpu: server.cpu_socket,
        ram: server.ram_socket,
        storage: server.storage_form_factor,
      };

      
      this.soc = null;
      this.soc = server.soc;

      this.config = {};
      if (server.default_spec) {
        Object.keys(server.default_spec).forEach((key) => {
          this.config[key] = server.default_spec[key];
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@include bp-down(md) {
  .server-card {
    .card-title {
      display: none;
    }
  }
}
.server-card {
  height: auto;
  .card-body {
    display: flex;
    justify-content: center;
    align-items: center;
    .el-select {
      margin: 0rem 2rem;
      width: 60%;
    }
  }
}

.modal-card {
  border: 1px solid $default;
  height: auto;
  .row {
    .col-6 {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .btn-col {
      align-items: center;
    }
  }
  .card-title {
    font-size: 20px;
    font-weight: 600;
  }
  .el-button {
    font-size: 18px !important;
  }
  &:hover {
    border: 1px solid $default;
  }
}

@include bp-down(md) {
  .el-dialog {
    width: 90% !important;
  }
  .modal-card {
    .card-title {
      margin-bottom: 1rem;
    }
    .el-button {
      font-size: 18px !important;
    }
  }
}
</style>
