<template>
  <div class="row mb-4">
    <div class="col-12 col-md-6">
      <h2 class="title-w3">Comparison results</h2>
    </div>
    <div class="col-12 col-md-6 legend-col">
      <div class="legend">
        <div class="legend__label" v-for="item in categories" :key="item.key">
          <div
            class="legend__label-icon"
            :class="`legend__label-icon--${item.color}`"
          />
          <span>{{ item.label }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EoLResults',
  props: ['categories'],
};
</script>
