const categories = [
  {
    label: 'Landfilling',
    data: [0, 0, 0, 0],
    color: 'purple',
    key: 'landfilling',
  },
  {
    label: 'Conventional Recycling',
    data: [0, 0, 0, 0],
    color: 'blue',
    key: 'recycling',
  },
  {
    label: 'CEDaCI Recycling',
    data: [0, 0, 0, 0],
    color: 'green',
    key: 'cedaci_recycling',
  },
  {
    label: 'Default Refurbishment',
    data: [0, 0, 0, 0],
    color: 'orange',
    key: 'refurbishing_default',
  },
  {
    label: 'Minimum Refurbishment',
    data: [0, 0, 0, 0],
    color: 'orange',
    key: 'refurbishing_minimum',
  },
  {
    label: 'Maximum Refurbishment',
    data: [0, 0, 0, 0],
    color: 'orange',
    key: 'refurbishing_maximum',
  },
];

const defaultCategories = [
  'landfilling',
  'recycling',
  'cedaci_recycling',
  'refurbishing_minimum',
];

const refurbishmentOptions = [
  'refurbishing_default',
  'refurbishing_minimum',
  'refurbishing_maximum',
];

const requirements = [
  {
    key: 'refurbishing_default',
    required: ['psu', 'fan', 'cpu'],
  },
  {
    key: 'refurbishing_minimum',
    required: ['psu', 'fan'],
  },
  {
    key: 'refurbishing_maximum',
    required: ['psu', 'fan', 'cpu', 'ram', 'storage', 'expansion'],
  },
];

export { categories, defaultCategories, refurbishmentOptions, requirements };
