import {
  categories,
  defaultCategories,
  refurbishmentOptions,
  requirements
} from '@/utils/data/eol';

import _ from 'lodash';


export default class EndOfLife {
  static getDefaultCategories() {
    let options = [];
    for (let i in defaultCategories) {
      const option = categories.find((o) => o.key === defaultCategories[i]);
      options.push(option);
    }

    return options;
  }

  static getRefurbishmentOptions() {
    let options = [];

    for (let i in refurbishmentOptions) {
      const option = categories.find((o) => o.key === refurbishmentOptions[i]);
      options.push(option);
    }

    return options;
  }

  static updateCategories(cat, newVal, oldVal) {

    if (newVal === oldVal) return cat;

    let options = [];

    const newOption = categories.find((o) => o.key === newVal);

    for (let i in cat) {
      if (cat[i].key !== oldVal) options.push(cat[i]);
    }

    options.push(newOption);

    return _.uniqBy(options, 'key');
  }

  static getRequirements(category) {
    const option = requirements.find((item) => item.key === category);
    return option.required;
  }

  static validateRequirements(category, config) {
    const required = this.getRequirements(category);
    const keys = Object.keys(config);

    let isValid = true;

    for (let i in required) {
      if (!keys.includes(required[i])) isValid = false;
    }

    return {
      isValid,
      errMsg: isValid ? '' : this.getErrMessage(category)
    }

  }

  static getErrMessage(category) {
    switch(category) {
      case 'refurbishing_minimum':
        return 'Minimum refurbishment requires at least 1 fan and 1 psu';
      case 'refurbishing_default':
        return 'Default refurbishment requires at least 1 psu, 1 fan and 1 processor';
      case 'refurbishing_maximum':
        return 'Maximum refurbishment requires all components to be configured';
      default:
        return '';
    }
  }
}
