<template>
  <apexchart
    type="bar"
    height="350"
    :options="chartOptions"
    :series="chartData"
  ></apexchart>
</template>

<script>
export default {
  name: 'EoLChart',
  props: ['chartData'],
  data() {
    return {
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        legend: {
          show: false,
        },
        colors: [
          '#ccc2fd',
          '#49c2c5',
          '#c0efbf',
          '#ffb99b',
          '#FF6666',
          '#a8a8a8',
        ],
        dataLabels: {
          enabled: true,
        },
        xaxis: {
          categories: [
            'Environmental Impact',
            'Life Cycle Costing',
            'Social Impact',
            'Criticality Risk',
          ],
        },
        yaxis: {
          max: 100,
          tickAmount: 5
        }
      },
    };
  },
};
</script>
